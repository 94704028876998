/** @type {{[key in Uppercase<ModuleType>]: ModuleType}} */
module.exports = {
  TYRES: 'tyres',
  TYRES_MANUFACTURERS: 'tyres_manufacturers',
  TYRES_WHOLESALERS: 'tyres_wholesalers',
  COLLECTIBLES: 'collectibles',
  RIMS: 'rims',
  CHAINS: 'chains',
  CONSUMABLES: 'consumables',
  PROMOTIONS: 'promotions',
  SPAREPARTS: 'spare-parts',
  BATTERIES: 'batteries',
  BIG_VOLUMES: 'big_volume',
  UNITARY: 'unitary',
};
