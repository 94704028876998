import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import Cookies from 'js-cookie';

// Context
import { useAppContext } from '../store/AppContext';

// Hooks
import useRouter from './useRouter';
import useCart from './useCart';

const useLogout = () => {
  const [, dispatch] = useAppContext();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { shouldCartBeReset, resetCart } = useCart();

  const logout = useCallback(() => {
    Cookies.remove('token');
    Cookies.remove('user');
    queryClient.clear();
    router.push('/login');
    if (shouldCartBeReset()) {
      resetCart();
    }
  }, [dispatch, router, queryClient]);
  return logout;
};

export default useLogout;
