// Images
import TyresDefaultImage from '../../../public/images/tyres/default.png';
import ChainsDefaultImage from '../../../public/images/chains/snow_chain.png';
import RimsDefaultImage from '../../../public/images/rims/default.png';
import TruckDefaultImage from '../../../public/images/tyres/truck.png';
import AgriculturalDefaultImage from '../../../public/images/tyres/agricultural.jpeg';
import TwoWheelsDefaultImage from '../../../public/images/tyres/2wheels.jpeg';
import OtherDefaultImage from '../../../public/images/tyres/other.jpg';
import BatteriesDefaultImage from '../../../public/images/batteries/default.png';

// Constants
import { TYRES, TYRES_MANUFACTURERS, TYRES_WHOLESALERS } from '../../enum/search/modules';

const DEFAULT_IMAGE = TyresDefaultImage;

const DEFAULT_IMAGES_PER_TYPE = {
  rims: RimsDefaultImage,
  chains: ChainsDefaultImage,
  consumables: DEFAULT_IMAGE,
  batteries: BatteriesDefaultImage,
};

const DEFAULT_IMAGES_PER_VEHICLE_TYPE = {
  '2WHEELS': TwoWheelsDefaultImage,
  TRUCK: TruckDefaultImage,
  CIVIL_ENGINEERING: AgriculturalDefaultImage,
  FARMING: AgriculturalDefaultImage,
  OTHER: OtherDefaultImage,
};

// Sometimes the url can have typos, and make the image crash
// We force a validUrl with this, which will be an invalid
/**
 *
 * @param {string} url
 */
const sanitizedThumbnail = (url) => (url && (url.startsWith('http') || url.startsWith('/')) ? url : null);

/**
 *
 * @param {Product} product
 * @param {ModuleType} category
 */
const getProductImage = (product, category) => {
  if (sanitizedThumbnail(product?.thumbnailUrl)) {
    return product.thumbnailUrl;
  }
  if (category === TYRES || category === TYRES_MANUFACTURERS || category === TYRES_WHOLESALERS) {
    // @ts-ignore
    return DEFAULT_IMAGES_PER_VEHICLE_TYPE[product?.segment]?.src || DEFAULT_IMAGE?.src;
  }

  // TODO: Improve typing here in order to remove this ts-ignore
  // @ts-ignore
  return DEFAULT_IMAGES_PER_TYPE[category]?.src || DEFAULT_IMAGE?.src;
};

export default getProductImage;
