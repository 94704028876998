// Utils
import closeModal from '../../../src/utils/modal/closeModal';

// Icons
import CrossIcon from '../../../public/icons/cross.svg';

import styles from './Modal.module.scss';

/**
 * @param {Object} props
 * @param {String} props.id
 * @param {String} [props.title]
 * @param {React.ReactNode} [props.children]
 * @param {Number} [props.maxWidth]
 * @param {import('react').ReactElement} [props.buttons]
 * @param {Boolean} [props.disableCloseButton]
 * @param {Boolean} [props.disableCloseOverlay]
 * @param {Function} [props.onClose]
 */
function Modal({
  id,
  title,
  children,
  buttons,
  maxWidth,
  onClose,
  disableCloseButton = false,
  disableCloseOverlay = false,
}) {
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal(id);
    }
  };

  const handleOverlay = (/** @type {any} */ e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) handleClose();
  };

  return (
    <div className="modal micromodal-slide" id={id} aria-hidden="true">
      <div role="presentation" className="modal__overlay" onClick={disableCloseOverlay ? null : handleOverlay}>
        <div
          className="modal__container"
          role="dialog"
          aria-modal="true"
          aria-labelledby={`${id}-title`}
          style={{ maxWidth }}
        >
          <div className={`${styles.container} modal__inner-container`}>
            <header className="modal__header">
              {title ? (
                <h2 className="modal__title" id={`${id}-title`}>
                  {title}
                </h2>
              ) : null}
              {disableCloseButton ? null : (
                <button className="modal__close" aria-label="Close modal" onClick={handleClose}>
                  <CrossIcon width={20} height={20} />
                </button>
              )}
            </header>
            <main className="modal__content" id={`${id}-main`}>
              {children}
              {buttons ? <div className={`${styles.buttons} flex a-center j-end m-t-40`}>{buttons}</div> : null}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
