const handleVersionCheck = (getItem, setItem, dispatch, router) => {
  const appVersion = 10;
  if (getItem('appVersion') !== appVersion && typeof window !== 'undefined') {
    if (router.pathname !== '/') {
      router.push('/');
      dispatch({ type: 'RESET_STATE' });
      setItem('appVersion', appVersion);
    } else {
      dispatch({ type: 'RESET_STATE' });
      setItem('appVersion', appVersion);
    }
  }
};

export default handleVersionCheck;
