'use client';

import i18next from 'i18next';
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getOptions, languages } from './settings';

const runsOnServerSide = typeof window === 'undefined';

i18next
  .use(initReactI18next)
  .use(resourcesToBackend((language, namespace) => import(`../locales/${language}/${namespace}.json`)))
  .init({
    ...getOptions(),
    lng: 'fr',
    detection: {
      order: ['path', 'htmlTag', 'navigator'],
    },
    preload: runsOnServerSide ? languages : [],
  });

function useTranslation(lng, ns, options) {
  const ret = useTranslationOrg(ns, options);
  // This does nothing more, but maybe later we'll want to handle multiple languages
  return ret;
}

export default useTranslation;
