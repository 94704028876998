import micromodal from 'micromodal';
import modalConfig from './modalConfig';

const openModal = (id, config) => {
  if (document.querySelector(`#${id}`)) {
    micromodal.show(id, config ? { ...modalConfig, ...config } : modalConfig);
  }
};

export default openModal;
