import axios from 'axios';
import getApiUrlByHostname from '../utils/getApiUrlByHostname';

const Axios = axios.create({
  baseURL: getApiUrlByHostname(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(process.env.NEXT_PUBLIC_NGROK != null ? { 'Ngrok-Skip-Browser-Warning': 'true' } : {}),
  },
});

export default Axios;
