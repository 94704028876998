const ANALYTICS_ROLES = {
  // admin
  ROLE_TECHNICAL_SUPER_ADMIN: 'Super admin ZOL', // ZOL + Pneumaclic
  ROLE_SUPER_ADMIN: 'Super admin Pneumaclic', // Pneumaclic super admin
  ROLE_ADMIN: 'Admin', // Pneumaclic admin
  ROLE_KEY_ACCOUNT_CLIENT_ADMIN: 'Admin grand compte', // Key account admin
  ROLE_KEY_ACCOUNT_CLIENT_REGIONAL_ADMIN: 'Admin régional grand compte', // Key account regional admin

  // client
  ROLE_USER: 'Visiteur', // Viewer only, can't buy anything
  ROLE_CUSTOMER: 'Acheteur', // Can place an order
  ROLE_MAIN_USER: 'Directeur', // Same as ROLE_DIRECTOR
  ROLE_DIRECTOR: 'Directeur', // Can do anything
};

export default ANALYTICS_ROLES;
