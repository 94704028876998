import Cookies from 'js-cookie';

/**
 * @param {String} value
 * @returns {any | null}
 */
export default function getCookieObject(value) {
  const cookie = Cookies.get(value);
  try {
    return JSON.parse(cookie);
  } catch {
    if (cookie != null) {
      // eslint-disable-next-line no-console
      console.error('Error parsing cookie', value, cookie);
      Cookies.remove(value);
    }
    return null;
  }
}
