import getCookieObject from '../utils/cookie';
import { PERMISSIONS } from './permissionMaps';

const hasPermission = ({ permissionsForAllRoles, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissionsForAllRoles.some((permissionsRole) =>
    permissionsRole.some((permission) => scopes.includes(permission)),
  );
};

export const isGranted = (user, scopes) => {
  const permissionsForAllRoles = user?.roles ? user.roles.map((role) => PERMISSIONS[role]) : null;
  const permissionGranted = permissionsForAllRoles ? hasPermission({ permissionsForAllRoles, scopes }) : false;
  return permissionGranted;
};

export default function PermissionsGate({ children, scopes = [] }) {
  const user = getCookieObject('user');

  if (!isGranted(user, scopes)) return null;
  return children;
}
