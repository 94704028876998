export const NEXT = {
  CATALOG: {
    RESULTS: (category = '') => `/product/${category}`,
    DETAILS: (category = '', id = '') => `/product/${category}/${id}`,
  },
  CHECKOUT: (step = '') => `/checkout/${step}`,
  ORDERS_SORDER_TYRE: () => '/orders-sorder-tyres',
  LOGOUT: () => '/logout',
  LOGIN: () => '/login',
  ORDERS: {
    LIST: () => '/orders',
    DETAILS: (/** @type { string } */ id) => `/orders/${id}`,
  },
  NATIONWIDE_OPERATIONS: {
    LIST: () => '/admin/nationwide-operations',
    CREATE: () => '/admin/nationwide-operations/create',
    EDIT: (id) => `/admin/nationwide-operations/${id}`,
    DEFAULT_CARTS: (id) => `/admin/nationwide-operations/${id}/default-carts`,
    LOGISTIC_DISCOUNTS: (id) => `/admin/nationwide-operations/${id}/logistic-discounts`,
    SPECIFIC_DISCOUNTS: (id) => `/admin/nationwide-operations/${id}/specific-discounts`,
    PARTICIPATIONS: {
      LIST: (id) => `/admin/nationwide-operations/${id}/participations`,
      EDIT: (id, participationId) => `/admin/nationwide-operations/${id}/participations/${participationId}`,
      CREATE: (id, participationId) => `/admin/nationwide-operations/${id}/participations/${participationId}/create`,
      UPDATE_CART: (id, participationId, participationChildId) =>
        `/admin/nationwide-operations/${id}/participations/${participationId}/${participationChildId}`,
      UPDATE_SHIPPING: (id, participationId, participationChildId) =>
        `/admin/nationwide-operations/${id}/participations/${participationId}/${participationChildId}/shipping`,
    },
  },
  PARTICIPATIONS: {
    LIST: () => '/nationwide-operations',
    EDIT: (id) => `/nationwide-operations/${id}`,
    CREATE: (id) => `/nationwide-operations/${id}/participation/create`,
    UPDATE_CART: (id, participationId) => `/nationwide-operations/${id}/participation/${participationId}`,
    UPDATE_SHIPPING: (id, participationId) => `/nationwide-operations/${id}/participation/${participationId}/shipping`,
  },
  FLUX_CLIENTS: {
    LIST: () => '/admin/flux-clients',
    EDIT: (/** @type {number } */ id) => `/admin/flux-clients/${id}`,
    REQUESTS: (/** @type {number } */ id) => `/admin/flux-clients/${id}/requests`,
  },
  KEY_ACCOUNTS: {
    LIST: () => '/admin/key-accounts',
    EDIT: (/** @type {number} code */ code) => `/admin/key-accounts/${code}`,
    CUSTOMIZATION: (/** @type {number} code */ code, /** @type {boolean} */ anchor) =>
      `/admin/key-accounts/${code}/customisation${anchor ? '?anchor=banner' : ''}`,
    MARGIN: (/** @type {number} code */ code) => `/admin/key-accounts/${code}/margin`,
    ADMINISTRATORS: (/** @type {number} code */ code) => `/admin/key-accounts/${code}/administrators`,
    ADMINISTRATORS_ADD: (/** @type {number} code */ code) => `/admin/key-accounts/${code}/administrators/add`,
    ADMINISTRATORS_EDIT: (/** @type {number} code */ code, /** @type {number} adminId */ adminId) =>
      `/admin/key-accounts/${code}/administrators/${adminId}`,
  },
  ADMIN_CLIENTS: {
    LIST: () => '/admin/clients',
  },
  SUPPLIERS: {
    LIST: () => '/admin/suppliers',
    MARGINS: {
      LIST: () => '/admin/suppliers/margins',
      ADD: () => '/admin/suppliers/margins/edit',
      EDIT: (/** @type {number} */ id) => `/admin/suppliers/margins/edit?id=${id}`,
    },
    EDIT: {
      COMPANY: (/** @type {number} */ id) => `/admin/suppliers/${id}`,
      DELIVERY: (/** @type {number} */ id) => `/admin/suppliers/${id}/delivery`,
      TAXES: (/** @type {number} */ id) => `/admin/suppliers/${id}/taxes`,
      TAXES_LOCATION: (/** @type {number} */ id) => `/admin/suppliers/${id}/taxes/location`,
      FRANCO: (/** @type {number} */ id) => `/admin/suppliers/${id}/franco`,
      PRODUCT_MARGINS: (/** @type {number} */ id) => `/admin/suppliers/${id}/product-margins`,
      SPECIFIC_DISCOUNTS: (/** @type {number} */ id) => `/admin/suppliers/${id}/specific-discounts`,
      SPECIFIC_DISCOUNTS_ADD: (/** @type {number} */ id) => `/admin/suppliers/${id}/specific-discounts/edit`,
      SPECIFIC_DISCOUNTS_EDIT: (/** @type {number} */ id, /** @type {number} */ discountId) =>
        `/admin/suppliers/${id}/specific-discounts/edit?discountId=${discountId}`,
      LOGISTIC_DISCOUNTS: (/** @type {number} */ id) => `/admin/suppliers/${id}/logistic-discounts`,
      LOGISTIC_DISCOUNTS_ADD: (/** @type {number} */ id) => `/admin/suppliers/${id}/logistic-discounts/edit`,
      LOGISTIC_DISCOUNTS_EDIT: (/** @type {number} */ id, /** @type {number} */ discountId) =>
        `/admin/suppliers/${id}/logistic-discounts/edit?discountId=${discountId}`,
      PARTNER_DISCOUNTS: (/** @type {number} */ id) => `/admin/suppliers/${id}/partner-discount`,
      PARTNER_DISCOUNTS_ADD: (/** @type {number} */ id) => `/admin/suppliers/${id}/partner-discount/edit`,
      PARTNER_DISCOUNTS_EDIT: (/** @type {number} */ id, /** @type {number} */ discountId) =>
        `/admin/suppliers/${id}/partner-discount/edit?discountId=${discountId}`,
    },
    ADD: () => '/admin/suppliers/create',
  },
};

export const API = {
  USER: {
    ME: () => '/user/me',
  },
  CATALOGUE: {
    MODULE: (module = '') => `/catalogue/${module}`,
  },
  REFRESH_TOKEN: () => 'token/refresh',
};
