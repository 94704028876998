/**
 * @param {Object} props
 * @param {number} [props.width]
 * @param {string} [props.color]
 * @param {number} [props.opacity]
 * @param {boolean} [props.isMini]
 */
function SpinLoader({ width = 40, color = '#000000', opacity = 0.5, isMini = false }) {
  return (
    <div className={`w-100 flex center ${!isMini ? 'p-60' : ''}`}>
      <svg width={width} height={width} viewBox="-20.5 -20.5 43 43" xmlns="http://www.w3.org/2000/svg" stroke={color}>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="3">
            <circle strokeOpacity={opacity} cx="0" cy="0" r="20" strokeWidth="3" />
            <path d="M20 0c0-9.94-8.06-20-20-20">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default SpinLoader;
