'use client';

/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import * as axios from 'axios';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

// Services
import Axios from '../../../src/services/_api';

// Hooks
import useImpersonate from '../../../src/hooks/useImpersonate';
import useLogout from '../../../src/hooks/useLogout';

// Utils
import getApiUrlByHostname from '../../../src/utils/getApiUrlByHostname';
import { isSOrder } from '../../../src/utils/platform';
import getCookieObject from '../../../src/utils/cookie';

// Constants
import { API, NEXT } from '../../../src/constants/routes';
import { TYRES } from '../../../src/enum/search/modules';

function AxiosInterceptor() {
  const { handleImpersonateLogout } = useImpersonate();
  const logout = useLogout();

  /**
   * If we are on SOrder and we have a `tyres` module, we should call
   * PMC instead of SOrder's API, this function tells when we should call PMC
   * and on which Endpoints or Locations
   * @link https://copilot.zol.fr/issues/80079
   *
   * @param {axios.AxiosRequestConfig<any>} config
   * @returns boolean
   */
  const shouldChangeBaseUrl = (config) => {
    const priorityConditionsWhereChangeDoesNotApply = [
      !isSOrder(),
      config.url.includes(API.USER.ME()),
    ];

    const conditionsWhereBaseUrlShouldBeApplied = [
      [API.CATALOGUE.MODULE(TYRES)].some((route) => new RegExp(route).test(config.url)),
      [NEXT.CATALOG.RESULTS(TYRES), NEXT.CHECKOUT(), NEXT.ORDERS_SORDER_TYRE]
        .some((route) => new RegExp(route).test(window.location.pathname)),
    ];

    if (priorityConditionsWhereChangeDoesNotApply.some(Boolean)) return false;
    if (conditionsWhereBaseUrlShouldBeApplied.some(Boolean)) return true;

    return false;
  };

  useEffect(() => {
    const requestInterceptor = Axios.interceptors.request.use((config) => {
      const userToken = getCookieObject('token');
      if (userToken?.token) {
        config.headers.Authorization = `Bearer ${userToken?.token}`;
      }
      return config;
    });

    const responseInterceptor = Axios.interceptors.response.use(
      (response) => response,
      async (err) => {
        const impersonateUser = getCookieObject('impersonateUser');
        const originalRequest = err.config;

        if (err.response?.status === 401) {
          if (!originalRequest._retry) {
            if (impersonateUser?.token) {
              handleImpersonateLogout();
            } else {
              originalRequest._retry = true;
              const userToken = getCookieObject('token');

              if (userToken?.refresh_token) {
                try {
                  const newUserAuth = await axios({
                    method: 'post',
                    url: `${getApiUrlByHostname()}/${API.REFRESH_TOKEN()}`,
                    data: {
                      refresh_token: userToken.refresh_token,
                    },
                  });

                  if (newUserAuth.data) {
                    Cookies.set('token', JSON.stringify({
                      token: newUserAuth?.data?.token,
                      refresh_token: newUserAuth?.data?.refresh_token,
                    }));
                    originalRequest.headers.Authorization = `Bearer ${newUserAuth.data.token}`;

                    return Axios(originalRequest);
                  }
                } catch (error) {
                  logout();
                }
              } else if (window.location.pathname !== NEXT.LOGIN()) {
                logout();
              }
            }
          } else if (window.location.pathname !== NEXT.LOGIN()) {
            logout();
          }
        }

        return Promise.reject(err);
      },
    );

    return () => {
      Axios.interceptors.response.eject(responseInterceptor);
      Axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);
  return null;
}

export default AxiosInterceptor;
