import { useMemo } from 'react';
import { useRouter as useNextRouter, usePathname, useParams, useSearchParams } from 'next/navigation';

const useRouter = () => {
  const router = useNextRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const params = useParams();

  const asPath = useMemo(() => {
    const path = [pathname];
    if (searchParams.toString().length > 0) {
      path.push(searchParams.toString());
    }
    return path.join('?');
  }, [pathname, searchParams]);

  const query = useMemo(() => {
    const obj = { ...params };
    searchParams.forEach((value, key) => {
      if (key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].push(value);
        } else {
          obj[key] = [obj[key], value];
        }
      } else {
        obj[key] = value;
      }
    });
    return obj;
  }, [searchParams]);

  return {
    ...router,
    searchParams,
    /**
     * @type {String} - Computation coming from useSearchParams and usePathname
     */
    asPath,
    pathname,
    query,
  };
};

export default useRouter;
