const { EIOS, SORDER, PPS, HAPPYCAR } = require('../constants/platform');

function isSOrder(hostname) {
  if (typeof window === 'undefined' && hostname == null) return false;

  if (process.env.NEXT_PUBLIC_LOCALHOST_EIOS === 'true') {
    return true;
  }
  return [EIOS, SORDER].some((elem) => (hostname || window.location.hostname).includes(elem));
}

function sorderDomain(hostname) {
  if (typeof window === 'undefined' && hostname == null) return '';

  const location = hostname || window.location.hostname;
  if (location.includes(PPS)) {
    return 'pps';
  }
  if (location.includes(HAPPYCAR)) {
    return 'happycar';
  }
  return 'sorder';
}

function isPPSHC(hostname) {
  if (typeof window === 'undefined' && hostname == null) return false;

  return [PPS, HAPPYCAR].some((elem) => (hostname || window.location.hostname).includes(elem));
}

// This is exported this way to make it easily stubbable in cypress
module.exports = {
  isSOrder,
  sorderDomain,
  isPPSHC,
};
