import { TYRES, RIMS, CHAINS, BATTERIES } from './search/modules';

const enumProductCategories = [
  {
    id: 1,
    key: TYRES,
  },
  {
    id: 2,
    key: RIMS,
  },
  {
    id: 3,
    key: CHAINS,
  },
  {
    id: 4,
    key: BATTERIES,
  },
];

export default enumProductCategories;
