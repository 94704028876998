import { cloneDeep } from 'lodash';
import Cookies from 'js-cookie';

// Hooks
import useRouter from './useRouter';

// Utils
import getCookieObject from '../utils/cookie';

// Services
import { getConnectedUser } from '../services/user/userService';

const useImpersonate = () => {
  const router = useRouter();
  const impersonateUser = getCookieObject('impersonateUser');
  const impersonateToken = getCookieObject('impersonateToken');
  const impersonateKeyAccountContext = getCookieObject('impersonateKeyAccountContext');

  /**
   * @param {Object} newImpersonationToken
   */
  const handleImpersonate = (newImpersonationToken) => {
    Cookies.set(
      'impersonateUser',
      JSON.stringify({
        ...getCookieObject('user'),
        redirectUrl: router.asPath,
      }),
    );
    Cookies.set('impersonateKeyAccountContext', Cookies.get('keyAccountContext'));
    Cookies.set('impersonateToken', Cookies.get('token'));

    Cookies.set('token', JSON.stringify(newImpersonationToken));
    getConnectedUser().then(({ data }) => {
      Cookies.set('user', JSON.stringify(data.data));
      router.push('/');
    });
  };

  const handleImpersonateLogout = () => {
    if (impersonateUser) {
      const impersonate = cloneDeep(impersonateUser);
      const redirect = impersonate?.redirectUrl || null;

      Cookies.set('user', JSON.stringify(impersonateUser));
      Cookies.set('token', JSON.stringify(impersonateToken));
      Cookies.set('keyAccountContext', JSON.stringify(impersonateKeyAccountContext));
      Cookies.remove('impersonateUser');
      Cookies.remove('impersonateToken');
      Cookies.remove('impersonateKeyAccountContext');

      if (redirect) {
        router.push(redirect);
      }
    }
  };

  return {
    handleImpersonateLogout,
    handleImpersonate,
  };
};

export default useImpersonate;
