import Image from 'next/image';
import React from 'react';
import PropTypes from 'prop-types';

// Components
import SpinLoader from '../../atoms/SpinLoader/SpinLoader';

// Utils
import { useAppContext } from '../../../src/store/AppContext';

// Styles
import styles from './PageLoader.module.scss';

function PageLoader({ loading }) {
  const [{ keyAccountContext, isLoadingContext }] = useAppContext();

  // When the key account is not loaded yet and we are in server side,
  // the platform imformation is not yet known
  const shouldDisplayLogo = typeof window !== 'undefined';

  return (
    <div className={`${styles.body_loading} ${!loading && !isLoadingContext ? styles.none : ''}`}>
      <div className={styles.img_wrapper}>
        {shouldDisplayLogo ? (
          keyAccountContext?.logo ? (
            <Image
              width={300}
              height={200}
              alt={keyAccountContext?.label || ''}
              src={keyAccountContext?.logo}
            />
          ) : null
        ) : null}
      </div>
      <div className={styles.loader}>
        <SpinLoader width={40} color={keyAccountContext?.primaryColor} />
      </div>
    </div>
  );
}

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default PageLoader;
