const isAdmin = () => {
  if (typeof window === 'undefined') return '';

  const location = window.location.pathname;

  if (location.includes('/admin')) return true;
  return false;
};

export default isAdmin;
