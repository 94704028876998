import { isNaN } from 'lodash';

const getVatAmount = (amount) => {
  if (amount && !isNaN(amount)) {
    const vat = (amount * 20) / 100;
    return Math.round(vat * 100) / 100;
  }
  return 0;
};

export default getVatAmount;
