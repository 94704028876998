const isBrowser = (() => typeof window !== 'undefined')();

export const getStorageItem = (key) =>
  isBrowser && window.localStorage[key] ? JSON.parse(window.localStorage[key]) : '';

export const setStorageItem = (key, value) => {
  if (isBrowser) {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  return false;
};

const removeStorageItem = (key) => {
  if (isBrowser && window.localStorage[key]) {
    window.localStorage.removeItem(key);
  }
};

export const clearStorageAll = () => {
  // clear all
  if (isBrowser && window.localStorage && Object.keys(window.localStorage)?.length) {
    const allKeysButVersion = Object.keys(window.localStorage).filter((key) => key !== 'appVersion');
    allKeysButVersion.forEach((key) => removeStorageItem(key));
  }
};

// SESSION STORAGE

export const getSessionItem = (key) =>
  isBrowser && window.sessionStorage[key] ? JSON.parse(window.sessionStorage[key]) : '';

export const setSessionItem = (key, value) => {
  if (isBrowser) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  }

  return false;
};
