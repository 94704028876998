import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const initSentry = (extraConfig = null) => {
  if (process.env.NEXT_PUBLIC_SENTRY_ENV !== 'development') {
    Sentry.init({
      dsn: SENTRY_DSN || 'https://387440c2747a4c9794ab7fd94c7af0ae@o125949.ingest.sentry.io/6392669',
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1.0,
      // ...
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps
      environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
      allowUrls: [/.*/],
      beforeSend: (event) => {
        const newEvent = { ...event };
        newEvent.extra = {
          ...(newEvent.extra || {}),
          localStorage,
          cookies: document.cookie,
        };
        return newEvent;
      },
      ...extraConfig,
    });
  }
};

export default initSentry;
