/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

// Utils
import getProductImage from '../../../src/utils/product/getProductImage';

import styles from './ProductImage.module.scss';

function ProductImage({
  product, category, smallBrand, className, classNameImage,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={`${styles.image__wrapper} ${smallBrand ? styles.small_brand : ''} flex center ${className || ''}`}>
        <div className={`${styles.image} ${classNameImage || ''}`}>
          {category ? (
            <Image
              src={getProductImage(product, category)}
              fill
              style={{
                objectFit: 'contain',
              }}
              alt={product?.label}
            />
          ) : null}
        </div>
        {category !== 'rims' ? (
          <div className={`${styles.photo_legal__wrapper} flex center`}>
            <p className={`${styles.photo_legal} fs-12 mobile-fs-10`}>
              {t('products.nonContractual')}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

ProductImage.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  category: PropTypes.string,
  smallBrand: PropTypes.bool,
  className: PropTypes.string,
  classNameImage: PropTypes.string,
};

ProductImage.defaultProps = {
  category: null,
  smallBrand: false,
  className: null,
  classNameImage: null,
};

export default ProductImage;
